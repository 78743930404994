import LargeForm from 'components/forms/LargeForm'
import Modal from 'components/global/Modal'
import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'

import OverlayVideo from '../components/overlay-video'
import Slider from 'components/slider'
import { P } from 'components/typography/Paragraph'

export default function TEKSPage() {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	return (
		<Layout pageName="TEKS">
			<Section>
				<Row className="my-10">
					<Slider name="TEKS Page" />
				</Row>
			</Section>
			<Section>
				<Row>
					<Col width=" w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../../static/images/logos/arc-core-logo.png" alt="arc core logo" className="mr-auto mb-4 w-60 text-left" />
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Comprehensive TEKS Curriculum, K–12</span>
						<P size="md" className="mb-4">
							ARC Core<sup>®</sup> meets the literacy needs of all students in English and Spanish in any learning environment. Through extensive reading, writing,
							research, and analysis, students develop agency and expertise in a wide variety of disciplines, preparing them to lead in a changing world.
						</P>
						<Resource title="ARC Core Benefits | Teks" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
					<Col width="w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../images/teks/arc-core.png" alt="arc core logo" className="mb-4 h-96 w-full" />
						<P size="xs" className="text-center">
							Organized into thematic units, ARC Core provides flexibility for each district to customize its scope and sequence.
						</P>
					</Col>
				</Row>
			</Section>
			<Section>
				<Row>
					<Col width="w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../images/teks/kindergarten.jpg" alt="arc core logo" className="h-96 w-full " />
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">ARC Core Kindergarten</span>
						<P size="md" className="mb-4">
							ARC Core Kindergarten incorporates all the best practices of thematic inquiry with the best practices of early childhood instruction. Kindergarten
							students learn grade level (and above) science content as well as specific beginning reading skills as they learn to read, write, and think like
							researchers.
						</P>
						<div className="flex gap-4">
							<Button onClick={() => setShow(true)}>Watch a Classroom in Action</Button>
							<Resource title="ARC Core Placemat | Grade K (TEKS)" linkOnly>
								<Button>Learn More</Button>
							</Resource>
						</div>
					</Col>
					<Col width="w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../images/teks/spanish-solutions.jpg" alt="arc core logo" className="h-96 w-full " />

						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Spanish Solutions</span>
						<P size="md" className="mb-4">
							Our instructional materials, professional development, incentive programs, and digital data-tracking system all serve to support achieving the goal of
							every student (Spanish- and/or English-speaking) performing on or above grade level.
						</P>
						<Resource title="Spanish Parity | Teks" linkOnly linkOnlyStyle="block mt-auto">
							<Button>Learn More</Button>
						</Resource>
					</Col>
				</Row>
			</Section>
			<Section>
				<SectionTitle
					title="ARC Solutions"
					text="As part of ARC Core, or purchased independently, ARC's solutions provide districts and schools with the tools and best practices to accelerate student achievement."
				/>
				<Row>
					<Col width="w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../images/teks/reading-assessment.png" alt="arc core logo" className="h-96 w-full " />
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Formative Reading Assessment</span>
						<span className="mb-4 block">TEKS IRLA/ENIL</span>
						<P size="md" className="mb-4">
							The Independent Reading Level Assessment (IRLA) is a unified, standards-based framework for student assessment, evaluating text complexity, and
							curriculum and instruction. The (ENIL) parallels the IRLA while reflecting the different developmental stages of learning to read in Spanish in the
							lower grades.
						</P>
						<Resource title="Formative Assessment | Teks" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
					<Col width="w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../../static/images/digital-solutions/schoolpace-connect-teacher.jpg" alt="arc core logo" className="h-96 w-full" />
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">myARC</span>
						<span className="mb-4 block">with TEKS eIRLA/eENIL</span>
						<P size="md" className="mb-4">
							myARC provides access to performance data in SchoolPace, IRLA and ENIL eLibraries in ARC Bookshelf, educational minigames in ARC Adventures, and so much
							more—ensuring students, teachers, and leaders are equipped with the tools needed to succeed in any learning environment.
						</P>
						<Resource title="Schoolpace Connect | Teks" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
				</Row>
			</Section>
			<Section>
				<Row>
					<Col width="w-full md:w-1/2" className="my-4 md:my-0">
						<StaticImage src="../images/teks/classroom-libraries.jpg" alt="arc core logo" className="h-96 w-full" />
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Classroom Libraries</span>
						<P size="md" className="mb-4">
							American Reading Company (ARC) offers the best literary and informational texts with more than 115,000 books from over 250 publishers reviewed and
							evaluated, allowing ARC to create the best, most comprehensive collections on the market.
						</P>
						<Resource title="Teks Leveled Classroom Libraries" linkOnly linkOnlyStyle="mt-auto">
							<Button>Learn More</Button>
						</Resource>
					</Col>
					<Col width="w-full md:w-1/2 " className="my-4 md:my-0">
						<StaticImage src="../images/teks/professional-development.jpg" alt="arc core logo" className="h-96 w-full" />
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">Professional Development</span>

						<P size="md" className="mb-4">
							ARC Executive Coaches work side by side with district and school leaders as they organize all stakeholders around evidence of student learning.
							Additionally, ARC Coaches work with teachers in their own classrooms as they integrate the TEKS into their daily practice. All ARC professional
							development is structured around a gradual release model.
						</P>
						<Resource title="Job Embedded PL | Teks" linkOnly>
							<Button>Learn More</Button>
						</Resource>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<Row className="pb-6">
					<Col width="w-full md:w-1/3" className="mb-10 md:mb-0">
						<div className="flex h-full  flex-col shadow-lg">
							<StaticImage src="../images/teks/summer-semester.jpg" alt="" className="m-0 h-56 w-full p-0" />
							<span className="m-0 block bg-core-gl py-4 text-center text-xl font-bold text-white">Summer Semester</span>
							<div className="flex flex-auto flex-col px-6 pb-6 pt-4">
								<P size="sm" className="mt-2 mb-6">
									Summer Semester is an intensive reading intervention using close reading of informational text with nonfiction writing to develop academic
									vocabulary, background knowledge, and test preparedness and proficiency.
								</P>

								<Resource title="Summer Solutions | Teks" linkOnly linkOnlyStyle="mt-auto">
									<Button fullWidth>Learn More</Button>
								</Resource>
							</div>
						</div>
					</Col>
					<Col width="w-full md:w-1/3" className="mb-10 md:mb-0">
						<div className="flex h-full flex-col shadow-lg">
							<StaticImage src="../images/teks/100-book-challenge.jpg" alt="" className="m-0 h-56 w-full p-0" />
							<span className="m-0 block bg-ab-100 py-4 text-center text-xl font-bold text-white">100 Book Challenge</span>
							<div className="flex flex-auto flex-col  px-6 pb-6 pt-4">
								<P size="sm" className="mt-2 mb-6">
									The 100 Book Challenge is a structured formative assessment system for home and school. Rotating Classroom Libraries provide daily practice in
									texts at the appropriate level of challenge, in school and at home.
								</P>
								<Resource title="100 Book Challenge | Teks" linkOnly linkOnlyStyle="mt-auto">
									<Button fullWidth>Learn More</Button>
								</Resource>
							</div>
						</div>
					</Col>
					<Col width="w-full md:w-1/3" className="mb-10 md:mb-0">
						<div className="flex h-full flex-col shadow-lg">
							<StaticImage src="../images/teks/teks-irla-enil-starter-kits.jpg" alt="" className="m-0 h-56 w-full p-0" />
							<span className="m-0 block bg-ar-100 py-4 text-center text-xl font-bold text-white">TEKS IRLA/ENIL Starter Kits</span>
							<div className="flex flex-auto flex-col px-6 pb-6 pt-4">
								<P size="sm" className="mt-2 mb-6">
									Incorporate formative assessment and differentiated support with the IRLA/ENIL starter kit. As part of an Integrated Literacy block, your
									teachers will use the IRLA/ENIL as a tool for assessment, coaching, and instruction, and leveled libraries to support a variety of reading
									levels in one classroom.
								</P>
								<Resource title="IRLA and ENIL | Teks" linkOnly linkOnlyStyle="mt-auto">
									<Button fullWidth>Learn More</Button>
								</Resource>
							</div>
						</div>
					</Col>
				</Row>
			</Section>
			<Section margin="my-20">
				<Row center className="pb-6">
					<Col width="lg:w-1/2">
						<LargeForm pageName="TEKS" />
					</Col>
				</Row>
			</Section>
			<Modal isOpen={show} handleClose={handleClose} title={'ARC Core: Kindergarten'} wide>
				<OverlayVideo title="ARC Core: Kindergarten" />
			</Modal>
		</Layout>
	)
}
